import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Select
} from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import ContenModalProduct from '../ContentModalProduct'
import ClientSection from './ClientSection'
import Serie from '../../../models/serie'
import { storagePage } from '../storage'
import dayjs from 'dayjs'

export default function ContentTop({
    issueDate,
    dueDate,
    type,
    changeHandlerDate,
    changeHandlerType,
    serie,
    changeHandlerSerie,
    series
}: {
    issueDate: string
    dueDate: string
    type: string
    changeHandlerDate: (ev: any) => void
    changeHandlerType: (ev: any) => void
    serie: number
    changeHandlerSerie: (ev: any) => void
    series: Serie[]
}) {
    const { condition } = storagePage
    const isCash = condition === 'cash' ? true : false

    return (
        <>
            <Flex justifyContent="space-between">
                <Box alignSelf="center">
                    <ClientSection />
                </Box>
                <Flex gridGap={4}>
                    <FormControl>
                        <FormLabel>Fecha de emisión</FormLabel>
                        <Input
                            type="date"
                            value={issueDate}
                            onChange={changeHandlerDate}
                            name="issue_date"
                            min={dayjs().add(-10, 'day').format('YYYY-MM-DD')}
                            max={dayjs().format('YYYY-MM-DD')}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel hidden={true}>Fecha de venc.</FormLabel>
                        <Input
                            type="date"
                            name="due_date"
                            value={dueDate}
                            onChange={changeHandlerDate}
                            isDisabled={type == 'ticket' || isCash}
                            min={issueDate}
                            hidden={true}
                        />
                    </FormControl>
                </Flex>
            </Flex>
            <Grid templateColumns="repeat(3, 1fr)" mt={6} gap={4}>
                <FormControl>
                    <FormLabel>Tipo de comprobante</FormLabel>
                    <Select
                        placeholder="Seleccionar tipo de comprobante"
                        value={type}
                        onChange={ev => changeHandlerType(ev)}
                    >
                        <option value="ticket">Boleta</option>
                        <option value="invoice">Factura</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Serie</FormLabel>
                    <Select
                        placeholder="Seleccionar serie"
                        value={serie}
                        onChange={ev => changeHandlerSerie(ev)}
                    >
                        {series.map((item, index) => (
                            <option key={index} value={item.id}>
                                {item.serie}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <ContenModalProduct
                    element={click => (
                        <Button
                            bgColor={colors['alo-blue']}
                            color="white"
                            alignSelf="flex-end"
                            onClick={click}
                        >
                            + Agregar producto
                        </Button>
                    )}
                />
            </Grid>
        </>
    )
}
